<template>
  <div class="agreement-page">
    <HeaderComponent></HeaderComponent>
    <div class="paragraph">
      尊敬的用户：史丹利提醒您，在使用本系统服务之前，请仔细阅读本声明的下列条款：  
    </div>
    <div class="paragraph">
      第一条本系统尊重并保护所有用户的个人隐私，对于用户的账号等相关个人资料，非经用户许可或根据相关法律的强制性规定，本系统不会主动地泄露给第三方。
    </div>
    <div class="paragraph">
      第二条未经本公司允许，禁止将本系统的任何内容泄露给第三方；否则，将承担由此产生的一切法律责任。 
    </div>
    <div class="paragraph">
      第三条如因用户将个人密码告知他人或与他人共享账户而导致个人资料泄露的，本公司对由此产生的损失不承担任何责任。
    </div>
    <div class="paragraph">
      第四条凡已使用本系统的用户均视为已无条件接受本声明各项条款的约束。
    </div>
    <div class="paragraph">
      如对本声明持有异议的，请及时以书面形式向本公司提出。
    </div>
    <div class="paragraph">
      本声明的最终解释权归本公司
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/header/index.vue";
export default {
  components: {
    HeaderComponent
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.agreement-page {
  height: 100vh;
  background: #FAFBFC;
  .paragraph {
    width: 100vw;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 18px;
    font-size: 12px;
    color: #2C2C2C;
    text-align: left;
  }
}
</style>