<template>
  <div class="help-page">
    <HeaderComponent></HeaderComponent>
    <div class="paragraph">
      亲爱的用户，我们的验证短信正常都会在数秒内发送，如果您未收到短信，请参考如下常见情况进行解决。 
    </div>
    <div class="paragraph">
      1、您的手机安装了360安全卫士、腾讯手机管家等安全软件，验证码短信可能被拦截进了垃圾短信箱，请打开垃圾短信箱读取短信，并将此号码添加为白名单。
    </div>
    <div class="paragraph">
      2、由于电信运营商短信通道故障造成了短信发送时间延迟，请耐心稍等片刻或点击重新取验证码。
    </div>
    <div class="paragraph">
       3、如果您尝试了上述方法后均未解决，或存在其它问题，请通过以下方式获取协助：
    </div>
    <div class="paragraph">
      客服电话： 3833020
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/header/index.vue";
export default {
  components: {
    HeaderComponent
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.help-page {
  height: 100vh;
  .paragraph {
    width: 100vw;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 18px;
    font-size: 12px;
    color: #2C2C2C;
    text-align: left;
  }
}
</style>